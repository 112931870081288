/* Admin screen stylings starts here */  
  .sidebar {
    width: 180px;
    height: 100vh;
    background-color: #fbf6f4;
    color: rgb(147, 120, 120);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .li-link-class {
    color: rgb(147, 120, 120);
  }
  .sidebar-top,
  .sidebar-center,
  .sidebar-bottom {
    display: flex;
    align-items: center;
    width: 80%;
    border-bottom: 1px solid #ffffff;
    padding: 10px 0;
    margin-bottom: 10px;
  }
  
  .sidebar-center {
    padding: 0;
  }
  
  .sidebar-bottom {
    justify-content: center;
    border: none;
  }
  
  .logo {
    font-size: 24px;
    margin-right: 10px;
    cursor: pointer;
    padding-left: 10px;
  }
  
  .brand {
    font-weight: bold;
    cursor: pointer;
  }
  
  .list {
    list-style: none;
    padding: 0;
    width: 100%;
  }
  
  .list-item {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    padding: 10px;
    border-radius: 10px;
  }
  
  .list-item-icon {
    width: 30px;
    font-size: 20px;
  }
  
  .list-item.active,
  .list-item:hover {
    background-color: rgba(233, 192, 198);
    color: white;
  }
  
  .color-box {
    width: 20px;
    height: 20px;
    border-radius: 5px;
    border: 1px solid #9ca3af;
    margin-right: 15px;
    cursor: pointer;
  }
  
  .dark {
    background-color: #111827;
  }
  
  .night {
    background-color: #312e81;
  }
  
  .light {
    background-color: #f3f4f6;
  }
  
  .sidebar.night {
    background-color: #312e81;
    color: #b4bbc7;
  }
  
  .list-item.night.active {
    background-color: #4338ca;
  }
  
  .sidebar.light {
    background-color: #f3f4f6;
    color: #111827;
  }
  
  .list-item.light.active {
    background-color: #d1d5db;
    color: #111827;
  }
  
  @media only screen and (max-width: 768px) {
    .sidebar {
      width: 50px;
    }
    .brand,
    .list-item-text {
      display: none;
      color: #ffffff !important;
    }
  }
  
/* Admin screen stylings ends here */