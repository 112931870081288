p{
    margin-bottom: 0;
}
.categories-tab-main-div {
    padding: 70px 50px 70px 50px;
    background: white;
}
.category-image {
    width: 110px;
}
.categorybar-text {
    color: var(--bs-body-color);
    text-decoration: none;
}
@media(max-width: 768px) {
    .categories-tab-main-div {
        padding: 40px 40px;
    }
}
/* @media screen and (min-width: 320px) and (max-width: 350px) {
    .categories-tab-main-div {
        padding: 0px 50px 12px 50px;
    }
}
@media screen and (max-width: 576px) {
    .categories-tab-main-div {
        padding: 35px 50px 12px 50px;
    }
} */