@font-face {
    font-family: 'Monarch';
    src: url(./Fonts/Monarch.ttf);
}
*{
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    box-sizing: border-box;
}
body{
    overflow-x: hidden;
}
.rupee-symbol {
    font-family: 'Anek Latin', sans-serif;
}
/* Products screen styles starts here */
.label-text {
    font-size: 18px;
    font-weight: 500;
}
.product-desc-list {
    margin: 18px 0px 0px 38px;
    font-size: 18px;
}
.product-desc {
}
.readmore-button {
    color: #CE0E2D;
    background: none;
}
.desc-subhead {
    padding-top: 10px;
    font-size: 18px;
    font-weight: 500;
}
.add-to-cart {
    padding: 15px;
    margin-top: 15px;
    border-radius: 5px;
    background: orange;
    color: #fff;
    width: 100%;
}
.mobile-screen-btn {
}
.add-to-cart-mobile {
    z-index: 2;
    padding: 15px;
    margin-top: 15px;
    background: orange;
    color: #fff;
    width: 100%;
    position: fixed;
    bottom: 0;
}
.buy-now {
    padding: 15px;
    margin-top: 15px;
    border-radius: 5px;
    background: #CE0E2D;
    color: #fff;
    width: 100%;
}
.product-image-div {
    display: flex;
    justify-content: center;
    max-width: 420px;
    max-height: 320px;
}
.product-image {
    object-fit: contain;
    max-width: 100%;
}
.thumb-img-div {
    width: 100px;
}
.thumb-img {
    object-fit: contain;
    max-width: 100%;
}
ul.thumb {
    margin: 0 auto;
    padding: 0;
    float: left;
}
ul.thumb li {
    list-style: none;
    margin: 0 5px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    overflow: hidden;
}
ul.thumb li img {
    width: 100%;
}
/* .imgBox {
    width: 500px;
    height: 500px;
    border: 1px solid rgba(0, 0, 0, 0.2);
} */

.quantity-control {
    padding: 10px;
    background: #CE0E2D;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    border-radius: 10px;
}
.quantity-show {
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 500;
}

/* Product screen styles ends here */

/* Cart screen starts here */
.cartscreen {
    min-height: 100vh;
    margin-top: 50px;
}
.checkoutButton {
    background-color: #CE0E2D !important;
    border: none !important;
    width: 100% !important;
    padding: 10px !important;
}
.cart-screen-img-mobile {
    width: 80px;
}
.product-in-cart {
    background: #f2ced4;
    padding: 5px;
    border-radius: 10px;
    border: 1px solid #ce0e2d96;
}
/* Cart screen ends here */

/* Login screen stylings starts here */
.loginScreen{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.sign-in-img {
    max-width: 100%;
    object-fit: contain;
}
.forget-pw {
    margin-top: 10px;
    font-size: 12px;
    cursor: pointer;
}
/* Login screen stylings ends here */

/* Profile screen stylings starts here */
.profile-container {
    margin: 50px 0px 50px 0px;
}
.profile-head {
    color: black;
    background-color: #fbf6f4;
    padding: 40px;
    border-radius: 10px;
}
.profile-title-name {
    font-size: 24px;
    font-weight: 500;
}
.profile-phone-email {
    font-size: 14px;
}
.profile-edit-icon {
    font-size: 40px;
    margin-left: auto;
    display: flex;
    align-items: center;
}
/* Profile screen stylings ends here */

/* Shipping screen stylings starts here */
.shipping-user-details {
    border-bottom: 1px solid rgb(205, 205, 205);
}
/* Shipping screen stylings ends here */

/* Payment screen stylings starts here */
.paymentScreen {
    min-height: 100vh;
}
/* Payment screen stylings ends here */
/* Placeorder screen stylings starts here */
.placeorderScreen {
    min-height: 100vh;
}
.placeorder-button {
    background: #ce0e2d;
    padding: 10px 20px;
    border-radius: 5px;
    color: white;
}
@media screen and (max-width: 768px) {
    .order-summary-card {
        border-bottom: 1px solid rgba(0,0,0,.125);
    }
}
/* Placeorder screen stylings ends here */
/* Orderscreen stylings starts here */
.orderscreen {
    min-height: 100vh;
}
.select-shipping {
    width: 100%;
    background-color: #ce0e2d !important;
    border: #CE0E2D !important;
}
/* Orderscreen stylings ends here */

/* Product list screen styling starts here */
.productscreen-first-column {
    border: 1px solid rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 10px;
}
.product-screen-each-cat {
    font-size: 14px;
}
.sm-dropdown-button {
    margin: 19px 0 0 19px;
}
.sidebar-links {
    color: black;
}
.ayur-cat-div {
    border: 1px solid #C4C4C4;
    border-radius: 10px;
    margin: 0px 30px;
}
.cat-img-div {
    width: 224px;
}
.cat-img {
    object-fit: cover;
    width: 100%;
    border-radius: 10px;
}
.category-names {
    line-height: 15px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #2F294D;
    margin-top: 10px;
}
@media (min-width: 1200px) and (max-width: 1400px) {
    .cat-img-div {
        width: 272px;
    }
}
@media (min-width: 992px) and (max-width: 1199.9px) {
    .cat-img-div {
        width: 212px;
    }
}
@media (min-width: 768px) and (max-width: 991.9px) {
    .cat-img-div {
        width: 248px;
    }
}

.ayurveda-cat-btn {
    font-weight: 500;
    padding: 10px 15px;
    border-radius: 10px;
    margin: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
@media (max-width: 576px) {
    .ayurveda-cat-btn {
        font-weight: 400;
        font-size: 12px;
        padding: 5px 8px;
        border-radius: 10px;
        margin: 5px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 10px;
    }
}
/* Product list screen styling ends here */

/* Wishlist screen stylings starts here */
.wishlistScreen {
    min-height: 100vh;
    margin: 40px 0 0 40px;
}
/* Wishlist screen stylings ends here */

/* Admin-userEditScreen stylings starts here */
.adminUserEditScreen {
    min-height: 100vh;
}
/* Admin-userEditScreen stylings ends here */

/* Admin-productEditScreen stylings starts here */
.add-variant-btn {
    height: 37px !important;
}
/* Admin-productEditScreen stylings ends here */

/* My orders screen stylings starts here */
.order-id-td {
    max-width: 100px;
    overflow: hidden;
}
/* My orders screen stylings ends here */

/* Chairman_message page starts here */
.chairman-page {
    font-family: Arial, Helvetica, sans-serif;
}
.chairman-page p {
    font-family: Arial, Helvetica, sans-serif;
}
.chairman-img {
    max-width: 400px;
    margin: 25px 0px;
    border-radius: 10px;
}
.executives {
    max-width: 140px;
    border-radius: 10px;
}
.executivesd {
    max-width: 146px;
    border-radius: 10px;
}
.executives-mob {
    max-width: 110px;
    border-radius: 10px;
}
.executivesed-mob {
    max-width: 116px;
    border-radius: 10px;
}
/* Chairman_message page ends here*/

/* Careers screen stylings starts here */
.careers-screen-main-div {
    height: 100vh;
}
/* Careers screen stylings ends here */

/* Contact screen stylings starts here */
.contact-main-div {
    height: 100vh;
}
.contact-main-div p {
    font-size: 20px;
    line-height: 45px;
}
/* Contact screen stylings ends here */
/* About screen stylings starts here */
.about-page {
    font-family: Arial, Helvetica, sans-serif;
}
.about-page p {
    font-family: Arial, Helvetica, sans-serif;
}
.long-paras {
    text-align: justify;
}
/* About screen stylings ends here */

/* Directors screen stylings starts here */
.designation {
    font-size: 14px;
}
.person-name {
    font-size: 14px;
}
.designation-c {
    font-size: 18px;
}
.person-name-c {
    font-size: 18px;
    margin-bottom: 20px;
}
.chairman-img-2 {
    max-width: 400px;
    margin-top: 25px;
    border-radius: 10px;
}
/* Directors screen stylings ends here */