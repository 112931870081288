/* Category heading starts here */
  .category-heading-main-container {
    padding: 50px 0px;
  }
  .products-main-div {
    margin: 80px 0;
  }
  .pattiams-ayurveda-icon-img {
    width: 157px;
  }
  .pattiams-ayurveda-img {
    margin-top: -22px;
  }
  .pattiams-category-desc {
    font-family: 'Monarch', sans-serif;
    font-weight: 400;
    font-size: 36px;
    line-height: 46px;
    color: #2F294D;
  }

  /* Mobile heading stylings starts here */
  .pattiams-category-heading-mobile {
    background: rgba(147, 178, 120, 0.1);
    border: 0.5px solid #93B278;
    border-radius: 20px;
    padding: 20px;
    margin: 40px 0px;
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .pattiams-category-heading-text {
    font-family: 'Monarch';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 22px;
    text-align: center;
    color: #2F294D;
  }
  .readmore-btn {
    border: 1px solid;
    padding: 5px 15px;
    border-radius: 10px;
    text-decoration: none;
    background-color: #93B278;
    cursor: pointer;
  }
  .pattiams-ayurveda-icon a {
    color: rgb(255, 255, 255);
  }
  .pattiams-ayurveda-icon a:hover {
    color: inherit;
  }
  .mobile-cat-main-div {
    margin-top: 140px;
  }
  .mobile-category-icon {
    width: 150px;
  }
  @media screen and (max-width: 991.9px){
    .category-heading-main-container {
        padding: 60px 0px;
      }
    }
    /* Mobile heading stylings ends here */
    
    /* Category heading ends here */